import restaurant from "./images/richland.png";
import img_card from "./images/3.jpg";
import imgheaderrestaurant from "./images/welcom.jpg";
import imgheaderrestaurantmb from "./images/welcom2.jpg";

import imgheader from "./images/MG3D3966.jpg";

import imgheaderm from "./images/fbm.png";
import img2 from "./images/dress.png";
import imgheadermiini from "./images/4.png";


{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Vasile",
        mireasa: "Carolina",
        data: "9 August 2024",
        data_confirmare: "15 iulie 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "coronciucc5@gmail.com", 
       tel: "+37362089265",
       phone: "tel:+37362089265",
       viber: "viber://chat?number=%2B37362089265",
       whatsapp: "https://wa.me/+37362089265",
       messenger: "https://www.messenger.com/t/carolina.coronciuc.9",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/xx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Richland",
            data: "9 august 2024, vineri, ora 18:00",
            adress: "Comuna Grătiești",
            harta: "https://maps.app.goo.gl/iC6Cm13gDN1J6GXs8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10866.789736928076!2d28.8271568!3d47.0853967!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d1c8588b3f7%3A0x105ba631571eda8b!2sRichland!5e0!3m2!1sru!2s!4v1715355703737!5m2!1sru!2s"   
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "PS. Vă vom fi recunoscători și vom aprecia enorm dacă va fi respectat codul vestimentar - să fie exclusă culoarea albă! Vă mulțumim anticipat!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Dress code:",
            title2: "BLACK STYLE",
            message: "Vom aprecia respectarea codului vestimentar.",
            ps: 'Mulțumim!' 
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            img2: imgheaderrestaurantmb,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
           
            img: imgheadermiini,
          
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "",
            parintii_nume1: "",
            parintii_nume2: "",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "NAȘII",
            nasii_nume: "Constantin & Nelea",
        }
    ],

}

export default data;